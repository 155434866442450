<script setup lang="ts">
import { animate, scroll } from 'motion'
import type {
  VoixMediaFieldInterface,
  VoixTextFieldInterface,
  VoixWysiwygFieldInterface,
} from '@voix/types'

const props = defineProps<{
  fields: {
    backgroundImage: VoixMediaFieldInterface
    title: VoixTextFieldInterface
    description: VoixWysiwygFieldInterface
    dim: VoixTextFieldInterface
  }
}>()

defineSlice({
  fields: {
    backgroundImage: {
      type: 'media',
      label: 'Background (Parallax Image)',
      breakpoints: { default: { width: 1400, height: 1000 } },
    },
    title: { type: 'text', label: 'Title', editorLabel: true },
    description: { type: 'wysiwyg', label: 'Description' },
    dim: { type: 'text', label: 'Dim Amount' },
  },
  name: { label: 'Centered Text Parallax', group: 'Playa Collection' },
  templates: [
    {
      label: 'Centered Text Parallax',
      fields: {
        title: { value: 'Centered Text Parallax' },
        description: { value: '<p>Centered Text Parallax</p>' },
        backgroundImage: {
          value:
            'https:\/\/playa-cms-assets.s3.amazonaws.com\/media\/Hilton_Yucatan\/czmpd-rooftop-pool-01.jpg',
        },
        dim: { value: '0' },
      },
    },
  ],
})

const dimValue = computed(() => {
  if (props.fields.dim.value)
    return Number.parseFloat(props.fields.dim.value)

  return 0.5
})

onMounted(() => {
  const background = document.getElementById(props.fields.backgroundImage.value[0].id)
  const scrollOptions = {
    target: background,
    offset: ['start end', 'end start'],
  }

  if (background)
    scroll(animate(background, { top: ['-100%', '100%'] }), scrollOptions)
})
</script>

<template>
  <div class="relative min-h-[400px] overflow-hidden">
    <!-- Parallax Background Image -->
    <VoixMedia
      :id="fields.backgroundImage.value[0].id"
      class="absolute w-full h-screen object-cover z-10"
      :field="fields.backgroundImage"
    />

    <div
      v-if="dimValue > 0"
      class="absolute inset-0 z-10"
      :style="{ opacity: dimValue, backgroundColor: '#061f3e' }"
    />

    <div class="relative z-20">
      <div class="flex justify-center">
        <div class="container py-12 lg:py-16 text-center lg:px-16 min-h-[400px]">
          <h3 v-if="fields.title.value" class="mb-4 text-4xl font-sans-lato font-light">
            {{ fields.title.value }}
          </h3>
          <div v-if="fields.description.value" class="px-12" v-html="fields.description.value" />
        </div>
      </div>
    </div>
  </div>
</template>
